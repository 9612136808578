import { getHolderRewards } from '../config/contracts';
import { ethers } from 'ethers';
import { getSigner } from '../config/signer';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import getAirdrop from '../config/airdrop';

type ClaimingResult = {
  success: boolean;
  feedback: string;
};

/**
 * Uses code from: https://github.com/Anish-Agnihotri/merkle-airdrop-starter/
 *
 * Generate Merkle Tree leaf from address and value
 * @param {string} address of airdrop claimee
 * @param {string} value of airdrop tokens to claimee
 * @returns {Buffer} Merkle Tree node
 */
function generateLeaf(address: string, value: string): Buffer {
  return Buffer.from(
    // Hash in appropriate Merkle format
    ethers.utils.solidityKeccak256(['address', 'uint256'], [address, value]).slice(2),
    'hex'
  );
}

export default async function airdropClaim(rewards: string): Promise<ClaimingResult> {
  try {
    // Setup merkle tree
    const merkleTree = new MerkleTree(
      // Generate leafs
      Object.entries(await getAirdrop()).map(([address, tokens]) =>
        generateLeaf(
          ethers.utils.getAddress(address),
          ethers.utils.parseUnits(tokens.toString(), 0).toString()
        )
      ),
      // Hashing function
      keccak256,
      { sortPairs: true }
    );

    const address = await getSigner().getAddress();

    // Get properly formatted address
    const formattedAddress: string = ethers.utils.getAddress(address);
    const contract = getHolderRewards();

    // Get tokens for address
    const numTokens: string = ethers.utils.parseUnits(rewards, 0).toString();

    // Generate hashed leaf from address
    const leaf: Buffer = generateLeaf(formattedAddress, numTokens);
    // Generate airdrop proof
    const proof: string[] = merkleTree.getHexProof(leaf);

    const transaction = await contract.airdropClaim(formattedAddress, numTokens, proof);
    await transaction.wait(1);

    return { success: true, feedback: '' };
  } catch (e: Error | any) {
    return { success: false, feedback: e.message };
  }
}
