import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { getStakingRewards, getTokensCount } from '../actions/tokens';
import { getHolderRewards } from '../config/contracts';

declare const window: Window & typeof globalThis & { signer: any; wallet: string };
type IWalletState = Record<string, IWallet>;

export enum WalletStatus {
  connected = 'connected',
  loading = 'loading',
  disconnected = 'disconnected',
}

export const initialState: IWallet = {
  status: WalletStatus.disconnected,
  address: '',
  walletName: null,
  feedback: null,

  offerHelp: false,

  tokensCount: 0,
  specialCount: 0,
  foundingCount: 0,
  hasClaimed: false,
  holderRewards: '0',
  stakingRewards: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const connect = createAsyncThunk('wallet/connect', async (object: IProviderConfig) => {
  window.wallet = object.name;
  await object.provider.enable();
  const eth = new Web3(object.provider).eth;
  if (parseInt(process.env.REACT_APP_NETWORK_ID || '1') !== (await eth.net.getId())) {
    throw new Error('NET');
  }
  const accounts = await eth.getAccounts();
  window.signer = new ethers.providers.Web3Provider(object.provider).getSigner();
  const address = accounts[0] || '';
  const result = await refreshWallet(address);

  return {
    address: address,
    walletName: object.name,
    ...result,
  };
});

export const refreshWallet = async (address: string) => {
  try {
    const { tokensCount, specialCount, foundingCount, rewards } = await getTokensCount(address);
    const hasTokens = tokensCount + specialCount + foundingCount > 0;
    const contract = getHolderRewards();

    // Collect claimed status for address, if part of airdrop (tokens > 0)
    const hasClaimed = hasTokens && (await contract._airdropClaimed(address));

    const holderRewards = hasTokens && !hasClaimed ? rewards : '0';
    // const stakingRewards = await getStakingRewards();

    return {
      tokensCount,
      specialCount,
      foundingCount,
      hasClaimed,
      holderRewards,
      //   stakingRewards: stakingRewards ? parseInt(stakingRewards.toString()) : 0,
      stakingRewards: 0,
    };
  } catch (e) {
    console.log(e);
  }
};

export const slice = createSlice({
  name: 'wallet',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    disconnect: (state) => {
      state.status = WalletStatus.disconnected;
      state.address = null;
      state.walletName = null;
    },
    offerHelp: (state) => {
      state.offerHelp = 'metaMask' === window.wallet && state.status === WalletStatus.loading;
    },
    updateFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    updateStakingRewards: (state, action) => {
      state.stakingRewards = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(connect.pending, (state) => {
        state.status = WalletStatus.loading;
        state.feedback = null;
        state.offerHelp = false;
      })
      .addCase(connect.rejected, (state, action) => {
        state.status = WalletStatus.disconnected;
        state.offerHelp = false;
        const allowedNetwork: Record<string, string> = {
          '4': 'rinkeby',
          '3': 'ropsten',
          '1': 'mainnet',
        };
        state.feedback =
          'NET' === action.error.message
            ? 'Application is not supported for your selected network, please switch to Ethereum ' +
              allowedNetwork[process.env.REACT_APP_NETWORK_ID || '1']
            : 'Something went wrong.';
      })
      .addCase(connect.fulfilled, (state, action) => {
        state.status = 'connected';
        state.address = action.payload.address;
        state.walletName = action.payload.walletName;
        state.offerHelp = false;
        state.specialCount = action.payload.specialCount || 0;
        state.foundingCount = action.payload.foundingCount || 0;
        state.hasClaimed = action.payload.hasClaimed;
        state.tokensCount = action.payload.tokensCount || 0;
        state.holderRewards = action.payload.holderRewards || '0';
        state.stakingRewards = action.payload.stakingRewards || 0;
      });
  },
});

export const { disconnect, offerHelp, updateStakingRewards } = slice.actions;
export default slice.reducer;
export const selectWallet = (state: IWalletState): IWallet => state.wallet;
