import { airdrop } from '../airdrop/config.1649946422.json';

type Airdrop = {
  [index: string]: string;
};

const getAirdrop = (): Airdrop => {
  return airdrop;
};

// type IAirdrop = Record<string, number>;

// const getAirdrop = async (): Promise<IAirdrop> => {
//   const response = await fetch(process.env.REACT_APP_LION_AIRDROP || '', {
//     headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
//   });
//   const config = await response.json();

//   return config.airdrop;
// };

// Export config
export default getAirdrop;
