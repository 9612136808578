import { useDispatch, useSelector } from 'react-redux';
import { selectWallet, WalletStatus, updateStakingRewards } from '../state/wallet';
import { Navigate } from 'react-router-dom';
import airdropClaim from '../actions/airdropClaim';
import stakingClaim from '../actions/stakingClaim';
import { selectClaim, start, stop, updateResult } from '../state/claim';

// MUI
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function formatMoney(value: number, digits: number = 5): string {
  if (!value) {
    value = 0;
  }
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(parseFloat(`${value}`));
}

export function Summary() {
  const dispatch = useDispatch();
  const state = useSelector(selectWallet);

  // When user disconnects from wallet then redirect him to homepage.
  const claimState = useSelector(selectClaim);
  if (WalletStatus.disconnected === state.status) {
    return <Navigate to={'/'} />;
  }

  const claimAirdropRewards = async () => {
    await dispatch(start());
    const claimed = await airdropClaim(state.holderRewards);
    await dispatch(updateResult({ feedback: claimed.feedback, hasClaimed: claimed.success }));
  };

  const claimStakingRewards = async () => {
    await dispatch(start());
    const claimed = await stakingClaim();
    await dispatch(stop({ feedback: claimed.feedback }));
    if (!claimed.feedback) {
      await dispatch(updateStakingRewards(0));
    }
  };

  const hasRewards = parseInt(state.holderRewards) > 0;

  return (
    <div className={'curr-page-content'}>
      <Box sx={{ marginTop: '50px' }}>
        <Container maxWidth="sm">
          {!claimState.claiming && (claimState.hasClaimed || state.hasClaimed) && (
            <Alert severity="success">You have successfully claimed your rewards.</Alert>
          )}
          {!claimState.claiming && claimState.feedback && (
            <Alert severity="error" className={'claiming-error'}>
              Something went wrong.
            </Alert>
          )}
          <Typography component="div" className="summary-box">
            Lion Club Tokens
          </Typography>
          <Card className="statistic-box" sx={{ minWidth: 275, marginTop: 1 }}>
            <CardContent>
              <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                {state.tokensCount || 0}
              </Box>
            </CardContent>
          </Card>

          <Typography sx={{ marginTop: 5 }} component="div" className="summary-box">
            Special Edition Tokens
          </Typography>
          <Card className="statistic-box" sx={{ minWidth: 275, marginTop: 1 }}>
            <CardContent>
              <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                {state.specialCount || 0}
              </Box>
            </CardContent>
          </Card>

          <Typography sx={{ marginTop: 5 }} component="div" className="summary-box">
            Founding NFT Tokens
          </Typography>
          <Card className="statistic-box" sx={{ minWidth: 275, marginTop: 1 }}>
            <CardContent>
              <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                {state.foundingCount || 0}
              </Box>
            </CardContent>
          </Card>

          <Typography sx={{ marginTop: 5 }} component="div" className="summary-box">
            Airdrop Rewards
          </Typography>
          <Card className="statistic-box" sx={{ minWidth: 275, marginTop: 1, marginBottom: 5 }}>
            <CardContent>
              <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                {formatMoney(
                  claimState.hasClaimed ? 0 : parseInt(state.holderRewards) / Math.pow(10, 18),
                  5
                )}
              </Box>
            </CardContent>
          </Card>

          {(hasRewards && !state.hasClaimed && !claimState.claiming && !claimState.hasClaimed && (
            <Box sx={{ marginY: 5 }}>
              <div className="btn" onClick={() => claimAirdropRewards()}>
                Claim airdrop
              </div>
            </Box>
          )) ||
            ''}
          {(hasRewards && claimState.claiming && (
            <Box sx={{ marginY: 5 }}>
              <div className="btn btn-no-hover">
                <CircularProgress
                  size={18}
                  sx={{ float: 'left', marginLeft: -3, marginRight: 2, color: '#f9f7f0' }}
                />
                Claiming...
              </div>
            </Box>
          )) ||
            ''}

          {/* <Typography sx={{ marginTop: 5 }} component="div" className="summary-box">
            Staking Rewards
          </Typography>
          <Card className="statistic-box" sx={{ minWidth: 275, marginTop: 1, marginBottom: 5 }}>
            <CardContent>
              <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                {formatMoney(state.stakingRewards / Math.pow(10, 18), 5)}
              </Box>
            </CardContent>
          </Card> */}

          {/* {(state.stakingRewards && !claimState.claiming && (
            <Box sx={{ marginY: 5 }}>
              <div className="btn" onClick={() => claimStakingRewards()}>
                Claim staking rewards
              </div>
            </Box>
          )) ||
            ''} */}
          {/* {(state.stakingRewards && claimState.claiming && (
            <Box sx={{ marginY: 5 }}>
              <div className="btn btn-no-hover">
                <CircularProgress
                  size={18}
                  sx={{ float: 'left', marginLeft: -3, marginRight: 2, color: '#f9f7f0' }}
                />
                Claiming...
              </div>
            </Box>
          )) ||
            ''} */}
        </Container>
      </Box>
    </div>
  );
}
