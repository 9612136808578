import { getNftTokenManager, getHolderRewards } from '../config/contracts';
import { Token } from '@uniswap/sdk-core';
import { BigNumber } from 'ethers';
import { parseInt } from 'lodash';
import { HolderTokens, getHolderTokens } from '../config/holders';
import _ from 'lodash';
import { getSigner } from 'src/config/signer';
import { INftToken } from 'src/types/INftToken';

type TokenCounts = {
  tokensCount: number;
  specialCount: number;
  foundingCount: number;
  rewards: string;
};

export async function getTokensCount(address: string): Promise<TokenCounts> {
  const { lions, special_editions, founding_nfts, rewards } = await cached(
    address,
    24 * 60 * 60 * 1000,
    async (address: string) => {
      return await getHolderTokens(address);
    }
  );

  return {
    tokensCount: lions,
    specialCount: special_editions,
    foundingCount: founding_nfts,
    rewards,
  };
}

async function cached(
  address: string,
  lifetime: number,
  fallback: CallableFunction
): Promise<HolderTokens> {
  const cacheKey = `lion_tokens_count_for_address_${address}`;
  const cached = JSON.parse(localStorage.getItem(cacheKey) ?? '{}');

  if (cached?.e > new Date().getTime().toString()) {
    return new Promise((resolve) => {
      const { l: lions, s: special_editions, f: founding_nfts, r: rewards } = cached;
      resolve({ lions, special_editions, founding_nfts, rewards });
    });
  }

  const { lions, special_editions, founding_nfts, rewards } = await fallback(address);

  localStorage.setItem(
    cacheKey,
    JSON.stringify({
      e: new Date().getTime() + lifetime,
      l: lions,
      s: special_editions,
      f: founding_nfts,
      r: rewards,
    })
  );

  return { lions, special_editions, founding_nfts, rewards };
}

async function getSingleToken(address: string, index: number): Promise<INftToken> {
  const tokenManager = getNftTokenManager();
  const rewardHolder = getHolderRewards();

  const token: Token = await tokenManager.tokenOfOwnerByIndex(address, index);
  try {
    const accumulatedRewards = await rewardHolder.accumulated(token.toString());

    return {
      tokenId: token.toString(),
      reward: accumulatedRewards,
    } as INftToken;
  } catch (e) {
    return {
      tokenId: token.toString(),
      reward: BigNumber.from(0),
    } as INftToken;
  }
}

export async function list() {
  const nftManager = getNftTokenManager();
  const address = await getSigner().getAddress();
  const currentBalance = await nftManager.balanceOf(address);

  const promises: Array<Promise<INftToken>> = [];
  _.times(currentBalance.toNumber(), (index) => {
    promises.push(getSingleToken(address, index));
  });

  return _.sortBy(
    _.uniqBy(
      (await Promise.all(promises)).filter((token: INftToken | null) => token),
      'tokenId'
    ),
    [
      function (token: INftToken) {
        return parseInt(token.tokenId);
      },
    ]
  ).reverse() as Array<INftToken>;
}

export async function getStakingRewards(): Promise<BigNumber | null> {
  let stakingRewards: BigNumber | null = null;
  _.each(await list(), (token) => {
    if (null !== stakingRewards) {
      stakingRewards.add(token.reward);
    } else {
      stakingRewards = token.reward;
    }
  });

  return stakingRewards;
}
