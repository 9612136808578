export type HolderTokens = {
  lions: number;
  special_editions: number;
  founding_nfts: number;
  rewards: string;
};

export const getHolderTokens = async (address: string): Promise<HolderTokens> => {
  const response = await fetch(`https://explorer.sportsicon.com/api/lion/airdrop/${address}`, {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  });

  return await response.json();
};
