import { useSelector, useDispatch } from 'react-redux';
import getProviders from '../config/providers';
import { Navigate } from 'react-router-dom';
import { connect as connectThunk, offerHelp, selectWallet, WalletStatus } from '../state/wallet';

// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

type IAction = { (providerConfig: IProviderConfig): Object };

export function Home() {
  const connect: IAction = connectThunk;

  const wallet = useSelector(selectWallet);
  const providers = getProviders();
  const dispatch = useDispatch();
  if (WalletStatus.connected === wallet.status) {
    return <Navigate to="/summary" />;
  }

  // After 5 seconds of being unable to connect to selected wallet we will offer user a possibility to select another one.
  if (WalletStatus.loading === wallet.status) {
    setTimeout(() => {
      dispatch(offerHelp());
    }, 5000);
  }

  return (
    <div className={'curr-page-content'}>
      <Box>
        <div id="connect" style={{ marginTop: '-65px', height: '65px' }} />
        <Box data-vestid="connect-wallet-text" className="welcome-text">
          Connect your wallet
        </Box>
        <Container>
          {wallet.offerHelp && (
            <Container maxWidth="sm" sx={{ marginBottom: '15px' }}>
              <Alert onClick={() => window.location.reload()} severity="warning">
                In case of problems, you can try<a href="/"> different wallet!</a>
              </Alert>
            </Container>
          )}
          {wallet.feedback && (
            <Container maxWidth="sm" sx={{ marginBottom: '15px' }}>
              <Alert severity="error">{wallet.feedback}</Alert>
            </Container>
          )}
          <Box sx={{ marginBottom: '50px' }}>
            {WalletStatus.disconnected === wallet.status && (
              <div className="wallet-connector-list">
                {providers.map((object, key) => (
                  <div key={key} onClick={() => dispatch(connect(object))}>
                    <div className="single-wallet-option">
                      <div className="wallet-name">{object.description}</div>
                      <img
                        className="wallet-icon"
                        src={
                          require(`../assets/images/wallets/${object.name.toLowerCase()}.svg`)
                            .default
                        }
                        alt={object.description}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {WalletStatus.loading === wallet.status && (
              <div>
                <CircularProgress size={40} sx={{ color: '#449764' }} />
              </div>
            )}
          </Box>
        </Container>
      </Box>
    </div>
  );
}
