import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const getProviders = (): Array<IProviderConfig> => {
  return [
    {
      name: 'metaMask',
      description: 'MetaMask',
      provider: window.ethereum || null,
    },
    {
      name: 'walletConnect',
      description: 'WalletConnect',
      provider: new WalletConnectProvider({
        rpc: {
          [process.env.REACT_APP_NETWORK_ID || '1']: process.env.REACT_APP_NODE_PROVIDER_URL || '',
        },
      }),
    },
    {
      name: 'coinBase',
      description: 'Coinbase',
      provider: new CoinbaseWalletSDK({
        appName: process.env.REACT_APP_NFT_TOKENS_NAME || '',
        appLogoUrl: '/logo.png',
        darkMode: false,
      }).makeWeb3Provider(
        process.env.REACT_APP_NODE_PROVIDER_URL,
        parseInt(process.env.REACT_APP_NETWORK_ID || '1')
      ),
    },
  ].filter((element) => null !== element.provider);
};

export default getProviders;
