import { createSlice } from '@reduxjs/toolkit';

type IState = Record<string, IClaimState>;

type IClaimState = {
  feedback: string;
  hasClaimed: boolean;
  claiming: boolean;
};

export const initialState: IClaimState = {
  feedback: '',
  hasClaimed: false,
  claiming: false,
};

export const claimSlice = createSlice({
  name: 'claim',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateResult: (state, action) => {
      state.feedback = action.payload.feedback;
      state.hasClaimed = action.payload.hasClaimed;
      state.claiming = false;
    },
    stop: (state, action) => {
      state.feedback = action.payload.feedback;
      state.claiming = false;
    },
    start: (state) => {
      state.feedback = '';
      state.claiming = true;
    },
  },
});

export const { start, stop, updateResult } = claimSlice.actions;
export default claimSlice.reducer;
export const selectClaim = (state: IState): IClaimState => state.claim;
