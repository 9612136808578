import { Contract, ethers } from 'ethers';
import { getSigner } from './signer';
import { abi as INonfungibleTokenManager } from '../abi/INonfungibleTokenManager.sol/INonfungibleTokenManager.json';

export function getNftTokenManager(): Contract {
  const positionManagerAddress: string = process.env.REACT_APP_NON_FUNGIBLE_TOKEN_ADDRESS || '';

  return new ethers.Contract(positionManagerAddress, INonfungibleTokenManager, getSigner());
}

export function getHolderRewards(): Contract {
  return new ethers.Contract(
    // Contract address
    process.env.REACT_APP_HOLDER_REWARD_CONTRACT_ADDRESS ?? '',
    [
      // hasClaimed mapping
      'function _airdropClaimed(address) public view returns (bool)',
      // Claim function
      'function airdropClaim(address to, uint256 amount, bytes32[] calldata proof) external',
      
      // Staking method - how many rewards has user acculumated for certain token
      'function accumulated(uint tokenIndex) public view returns (uint)',
      // Claim staking reward funcation
      'function claim(uint[] memory tokenIndices) external',
    ],
    // Get signer from authed provider
    getSigner()
  );
}
