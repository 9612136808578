import './assets/styles/app.scss';
import { Home } from './pages/Home';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect, selectWallet } from './state/wallet';
import { Link, Route, Routes } from 'react-router-dom';
import { Summary } from './pages/Summary';

// MUI
import { AppBar, Box, ButtonGroup, Typography, Button, Container } from '@material-ui/core';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiLink from '@mui/material/Link';

import logo from './assets/images/logo.png';

function App() {
  const wallet = useSelector(selectWallet);
  const dispatch = useDispatch();
  const shortAddress =
    wallet.address && `${wallet.address.slice(0, 6)}...${wallet.address.slice(-4)}`;

  const header = (
    <AppBar>
      <Container className="app-bar-container">
        <Link to="/summary">
          <img className="logo-image" src={logo} alt="SportsIcon" />
        </Link>
        <Box className="top-details">
          <Box className="app-title">
            <Typography variant="h6" component="div" className="app-title-text">
              $LION Airdrop
            </Typography>
          </Box>
          {wallet.status === 'disconnected' && (
            <Button variant="contained" disabled>
              <AccountBalanceWalletIcon sx={{ marginRight: '10px' }} /> Disconnected
            </Button>
          )}

          {wallet.status === 'connected' && (
            <ButtonGroup disableElevation variant="contained">
              <Button variant="contained" disabled>
                <AccountBalanceWalletIcon sx={{ marginRight: '10px' }} /> {shortAddress}
              </Button>
              <Button onClick={() => dispatch(disconnect())} color="primary">
                Disconnect
              </Button>
            </ButtonGroup>
          )}

          {wallet.status === 'loading' && (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<AccountBalanceWalletIcon />}
              variant="outlined">
              Connecting...
            </LoadingButton>
          )}
        </Box>
      </Container>
    </AppBar>
  );

  const intro = (
    <Container maxWidth="lg" className="page-description-box">
      <h1>
        Claim your <span className="brand-green">$LION</span> airdrop
      </h1>
    </Container>
  );

  return (
    <div className="App">
      {header}
      {intro}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/summary" element={<Summary />} />
      </Routes>
      <Box sx={{ paddingBottom: 10 }}>
        <MuiLink
          href="https://etherscan.io/token/0xfdeB168c98b97bA5D8cb3939C6048f057F984611"
          underline="hover"
          color="#449764"
          target="_blank"
          rel="noreferrer"
          variant="h6">
          $LION Token on Etherscan
        </MuiLink>
      </Box>
      <Box sx={{ paddingBottom: 10 }}>
        <MuiLink
          href="https://info.uniswap.org/#/pools/0xee3e92f0eceb32b429caaad4ca72f7b54d5b78ee"
          underline="hover"
          color="#449764"
          target="_blank"
          rel="noreferrer"
          variant="h6">
          Liquidity Pool on Uniswap
        </MuiLink>
      </Box>
      <Box sx={{ paddingBottom: 40 }}>
        <MuiLink
          href="https://metamask.zendesk.com/hc/en-us/articles/360015489031-How-to-add-unlisted-tokens-custom-tokens-in-MetaMask"
          underline="hover"
          color="#449764"
          target="_blank"
          rel="noreferrer"
          variant="h6">
          How to add token to MetaMask
        </MuiLink>
      </Box>
    </div>
  );
}

export default App;
