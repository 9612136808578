import { configureStore } from '@reduxjs/toolkit';
import walletReducer from './wallet';
import claimReducer from './claim';

export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    claim: claimReducer,
  },
});
